.export-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 99999;
}

body.export-popup {
  overflow: hidden;
  position: fixed;
}

.export-popup-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  min-height: 0;
  /* max-height: 70vh; */
  min-width: 300px;
  transition: min-height 5s;
  background-color: #DEDEDE;

}


.modal-body-container-scrollable {
  height: 53vh;
  overflow-y: scroll;
}

.modal-body-container-scrollable1 {
  height: 28vh;
}

/* Hide scrollbar for Chrome, Safari and Opera */
/* .modal-body-container-scrollable::-webkit-scrollbar, .modal-body-container-scrollable1::-webkit-scrollbar { */
  /* display: none; */
/* } */

/* Hide scrollbar for IE, Edge and Firefox */
/* .modal-body-container-scrollable, .modal-body-container-scrollable1 { */
  /* -ms-overflow-style: none;  IE and Edge */
   /* scrollbar-width: none;  Firefox */
 /* }   */

/* .modal-body-container-internal { */
  /* min-height: 50vh; */
/* }  */

 /* @keyframes exp-cont-animate-height {
  100% {
    min-height: 57vh;
  }
}  */


