.sticky-submenu {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  background-color: #ededed;
}

.icon-grey {
  color: #e3e3e3;
}

.user-dialog {
  padding: 1rem 0 1rem 0;
  position: absolute;
  z-index: 1;
  top: 70px;
  right: 40px;
  max-width: 80%;
  min-height: 73px;
  background: #fefefe 0% 0% no-repeat padding-box;
  box-shadow: 3px 6px 3px #00000026;
  opacity: 1;
}

.user-dialog:before {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 10px 12px;
  border-color: transparent transparent #fefefe transparent;
  right: 8px;
  /* TOP IS DOUBLE THE HEIGHT OF THE BORDER */
  top: -20px;
}

.user-name {
  text-align: left;
  font: normal normal normal 14px/18px Montserrat;
  letter-spacing: 0px;
  color: #1a1a1a;
  opacity: 0.87;
  padding-left: 23.5px;
  padding-right: 18px;
  padding-bottom: 7px;
}

.logout-btn {
  padding-left: 23.5px;
  background: none;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-align: left;
  font: normal normal bold 14px/18px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.87;
}

.main-menu-logo {
  position: absolute;
  bottom: 0;
  width: 150px;
  margin-left: 20px;
  margin-bottom: 30px;
}

.menu-items-container {
  padding-top: 28px;
}

.no-underscore {
  text-decoration: none;
}

@media (min-width: 768px) {
  .user-dialog {
    top: 70px;
    max-width: 80%;
    min-width: 177px;
  }
}
