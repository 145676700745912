html {
  min-height: 100%;
}

.btn-orange {
  border-radius: 30px;
  background: rgb(243, 129, 30);
  white-space: nowrap;
  color: rgb(255, 255, 255);
  outline: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
}

.btn-brown {
  border-radius: 30px;
  background: rgb(115, 32, 2);
  white-space: nowrap;
  color: rgb(255, 255, 255);
  outline: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
}

.btn-login {
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.05em;
}

.cursor-pointer :hover {
  cursor: pointer;
}

.logo-height-15 {
  max-height: 15vh;
}

.bg-user-submenu {
  background-color: #e3e3e3;
  min-height: 3rem;
}