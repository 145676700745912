.index-bg {
  min-height: 100vh;
  background-position: center;
  background-size: cover;
}

.no-underscore {
  text-decoration: none;
}

.main-menu-icon-png {
  max-width: 32px;
  max-height: 32px;
}

.traffic-red {
  background-color: #ab3324;
}

.traffic-yellow {
  background-color: #f28121;
}

.traffic-green {
  background-color: #669740;
}

.lt-traffic-red {
  background-color: #bd615b;
}

.lt-traffic-yellow {
  background-color: #eaad82;
}

.lt-traffic-green {
  background-color: #92a082;
}

.lt-grey-bg {
  background-color: #F5F5F5;
}

.text-traffic-red {
  color: #EC0000;
}

.text-traffic-yellow {
  color: #F26D00;

}

.text-traffic-green {
  color: #00AE11;
}

.btn-primary-outline {
  background-color: transparent;
  border-color: #ccc;
  border: 0;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

body {
  font-family: 'Montserrat', sans-serif;
}

html {
  scroll-behavior: smooth;
  height: 100vh;
}

a {
  color: #000;
  text-decoration: none;
}

a:hover {
  color: #7A0000;
}

.text-armor-red {
  color: #7A0000;
}

.docs-wrapper {
  height: calc(100vh);
}

.left {
  float: left;
  background-color: #7A0000;
  width: 200px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 3rem;
  font-weight: 500;
}

.shadow-inner-right {
  -moz-box-shadow:    inset -7px 0 9px -7px rgba(0,0,0,0.4);
  -webkit-box-shadow: inset -7px 0 9px -7px rgba(0,0,0,0.4);
  box-shadow:         inset -7px 0 9px -7px rgba(0,0,0,0.4);
}

.right {
  float: left;
  width: calc(100% - 200px);
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 1rem;
  background: #F5F5F5 ;
}

.apiContent {
  padding-bottom: 5rem;
}

.main-content {
  min-height: 72%;
}

.top-menu {
  min-height: 15px;
}

/* .content-card {
  width: 100%;
  padding: 1rem 2rem !important;
  margin-bottom: 3rem !important;
} */

.no-underline {
  text-decoration: none;
}

.mainMenuLogoWrapper {
  margin: 25px;
}

.armor-card {
  background: #FFFFFF;
  padding: 2rem;
  box-shadow: 3px 6px 3px #00000026;
}

@media screen and (max-width: 1350px) {
  /* .left {
    width: 20%;
  }

  .right {
    width: 80%;
  } */

  /* .content-card {
    width: 80%;
  } */

  blockquote {
    width: 80%;
  }
}

@media screen and (max-width: 1050px) {
  /* .left {
    width: 25%;
  }

  .right {
    width: 75%;
  } */

  /* .content-card {
    width: 90%;
  } */

  blockquote {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .left {
    margin-left: -100%;
    height: 80%;
    position: absolute;
    z-index: 99;
    width: 100%;
  }

  .mainMenuLogoWrapper {
    max-width: 100px;
    margin: 25px;
  }

  .right {
    width: 100vw;
  }

  .armor-card {
    padding: 1rem;
  }

}

@-webkit-keyframes fadeIn { from {margin-left: -100%; } to { margin-left: 0%; } }
@-moz-keyframes fadeIn { from {margin-left: -100%; } to { margin-left: 0%; } }
@keyframes fadeIn { from {margin-left: -100%; } to { margin-left: 0%; } }

.navbar-opened {
  margin-left: 0%;
  -webkit-animation: fadeIn .15s ease;    
  -moz-animation: fadeIn .15s ease;
  animation: fadeIn .15s ease;
}

.slider-warning {
  --SliderOrangeColor: #F26D00;
  -webkit-appearance: none;
}

.slider-danger {
  --SliderRedColor: #EC0000;
  -webkit-appearance: none;
}

/* --------------------------- webkit browsers */
.slider-warning::-webkit-slider-thumb {
  background-color: var(--SliderOrangeColor);
}
/* -------------------------- Firefox */
.slider-warning::-moz-range-thumb { 
  background-color: var(--SliderOrangeColor);
}
/* --------------------------- webkit browsers */
.slider-danger::-webkit-slider-thumb {
  background-color: var(--SliderRedColor);
}
/* -------------------------- Firefox */
.slider-danger::-moz-range-thumb { 
  background-color: var(--SliderRedColor);
}

.border-left {
  border-left: #7A0000 5px solid;
}

.border-left-active {
  border-left: #DA551C 5px solid;
}

.menu-item-active {
  color: #DA551C;
}

.menu-item-active:hover, .menu-item-active:active {
  color: #DA551C;
}

.armor-page-link {
  color: #000;
  border: 0px;
  text-decoration: none;
}

.armor-page-link {
  color: #000;
}

.armor-page-item.active .armor-page-link {
  color: #7A0000;
  font-weight: 700;
  background-color: #FFF;
  text-decoration: none;
}

.armor-active-link-border {
  border-bottom: 2px solid #7A0000;
  min-width: 1rem;
}

.armor-link-border {
  border-bottom: 2px solid #7A0000;
  opacity: 0.2;
  min-width: 1rem;
}

.card-shadow {
  box-shadow: 3px 6px 3px #00000026;
}

.card-top-border {
  height: 8px;
}

.bg-traffic-red {
  background-color: #EC0000;
}

.bg-traffic-yellow {
  background-color: #F26D00;
}

.bg-traffic-green {
  background-color: #00AE11;
}

.bg-traffic-grey {
  background-color: #DEDEDE;
}

.mobile-menu-btn {
  width: 1px;
}